import React from 'react';
import CybersecurityGoogleMain from '../../assets/images/Certification/cybersecurity/google/Coursera main_page1.png';
import UiUxGoogleMain from '../../assets/images/Certification/UI_UX/google/Coursera main_page1.png'
import DataAnalystGoogleMain from '../../assets/images/Certification/data analyst/google/Coursera main_page1.png'
import PythonMichiganMain from '../../assets/images/Certification/python/Michigan uni/Coursera main_page1.png'
import CybersecurityMaylandMain from '../../assets/images/Certification/cybersecurity/Maryland uni/Coursera main_page1.png'
import highSchoolDegree from '../../assets/images/Certification/traditionalEducation/highdiploma.png'
import associate from '../../assets/images/Certification/traditionalEducation/associate.png'
import honors from '../../assets/images/Certification/traditionalEducation/honors.png'
import comptiaA from '../../assets/images/Certification/comptia/comptiaA.png'
import NetworkPlus from '../../assets/images/Certification/comptia/Network+.png'
import SecurityPlus from '../../assets/images/Certification/comptia/Security+.png'
import Cysa from '../../assets/images/Certification/comptia/Cysa+.png'


const Certifications = () => {
    return (
                
        <div className="container bg-pageBackground p-16 mx-auto px-4">
            <section id="traditional-education" className="mb-20">
                <h2 className="text-3xl font-bold mb-4 text-center fade-in">Traditional Education</h2>
                <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center relative">
                        <img src={associate} 
                        alt="Associate's Degree" 
                        className="w-64 h-48 mb-4 rounded-lg shadow- fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    <div className="flex flex-col items-center relative">
                        <img src={honors} 
                        alt="Honors Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    <div className="flex flex-col items-center relative">
                        <img src={highSchoolDegree} 
                        alt="High School Degree" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                </div>
            </section>

            <section id="comptia-certification" className="mb-20">
                    <h2 className="text-3xl font-bold mb-4 fade-in text-center">CompTIA Certification</h2>
                    <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                        <div className="flex flex-col items-center relative">
                            <img src={comptiaA} 
                            alt="Comptia Certification" 
                            className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                            />
                        </div>
                        <div className="flex flex-col items-center relative">
                            <img src={NetworkPlus} 
                            alt="Comptia Certification" 
                            className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                            />
                        </div>
                        <div className="flex flex-col items-center relative">
                            <img src={SecurityPlus} 
                            alt="Comptia Certification" 
                            className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                            />
                        </div>
                        <div className="flex flex-col items-center relative">
                            <img src={Cysa} 
                            alt="Comptia Certification" 
                            className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                            />
                        </div>
                        {/* Add more image-description pairs as divs here */}
                    </div>
            </section>


            <section id="cybersecurity-certification" className="mb-20">
                <h2 className="text-3xl font-bold mb-4 fade-in text-center">Cybersecurity Certification</h2>
                <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center relative">
                        <img src={CybersecurityGoogleMain} 
                        alt="Cybersecurity Google Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    <div className="flex flex-col items-center relative">
                        <img src={CybersecurityMaylandMain} 
                        alt="Cybersecurity Mayland Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    {/* Add more image-description pairs as divs here */}
                </div>
            </section>


            <section id="data-analytics-google" className="mb-20">
                <h2 className="text-3xl font-bold mb-4 fade-in text-center">Data Analytics Certification</h2>
                <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center relative">
                        <img src={DataAnalystGoogleMain} 
                        alt="Data Analytics Google Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    {/* Add more image-description pairs as divs here */}
                </div>
            </section>


            <section id="python-michigan" className="mb-20">
                <h2 className="text-3xl font-bold mb-4 fade-in text-center">Python Certification</h2>
                <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center relative">
                        <img src={PythonMichiganMain} 
                        alt="Python Michigan Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50" 
                        />
                    </div>
                    {/* Add more image-description pairs as divs here */}
                </div>
            </section>


            <section id="ui-ux-google" className="mb-20">
                <h2 className="text-3xl font-bold mb-4 fade-in text-center">UI/UX Certification</h2>
                <div className="flex flex-col md:flex-row flex-wrap items-center justify-center md:space-x-8 space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center relative">
                        <img src={UiUxGoogleMain} 
                        alt="UI/UX Certification" 
                        className="w-64 h-48 mb-4 rounded-lg shadow-lg fade-in transform transition-transform duration-500 hover:scale-150 hover:z-50"
                        />
                    </div>
                    {/* Add more image-description pairs as divs here */}
                </div>
            </section>

            
        </div>
    )
}


export default Certifications;
